<template>
  <div class="container-fluid">
    <div class="row d-flex wrapper" style="margin-right: 0; margin-left: 0">
      <div class="col-lg-6 d-none d-sm-block">
        <div class="card1 pb-5">
          <div class="row"><img :src="image.logo" class="logo" /></div>
          <div class="row justify-content-center">
            <img :src="image.left" class="image" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col">
        <div class="row d-flex justify-content-center">
          <div class="col-sm-8 col">
            <div class="card2 border-0 py-5">
              <h4 class="text-center mb-4 d-none d-sm-block">
                Sign in Livechat
              </h4>
              <h4 class="text-center d-block d-sm-none">
                <img :src="image.logo" class="logo" style="margin: 0" />
              </h4>
              <p class="text-center d-none d-sm-block">
                Welcome, enjoy your work and have a nice day
              </p>

              <form
                @submit.prevent.stop="onSubmit()"
                class="shadow p-4 bg-white rounded-bottom"
              >
                <div class="row px-3 mb-1">
                  <label class="mb-1 sr-only">
                    <h6 class="mb-0 text-sm">Email Address</h6>
                  </label>
                  <input
                    :class="checkField('email')"
                    v-model="$v.form.email.$model"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                  />
                </div>
                <div class="row px-3 mb-1 position-relative">
                  <label class="mb-1 sr-only">
                    <h6 class="mb-0 text-sm">Password</h6>
                  </label>
                  <input
                    :class="checkField('password')"
                    v-model="$v.form.password.$model"
                    :type="showpwd ? 'text' : 'password'"
                    name="password"
                    placeholder="Enter password"
                  />
                  <div
                    class="position-absolute d-flex align-items-center"
                    style="right: 1.5em; cursor: pointer; height: 100%"
                    @click="showpwd = !showpwd"
                  >
                    <svg
                      v-if="!showpwd"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-eye"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                      <path
                        d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-eye-closed"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M21 9c-2.4 2.667 -5.4 4 -9 4c-3.6 0 -6.6 -1.333 -9 -4"
                      />
                      <path d="M3 15l2.5 -3.8" />
                      <path d="M21 14.976l-2.492 -3.776" />
                      <path d="M9 17l.5 -4" />
                      <path d="M15 17l-.5 -4" />
                    </svg>
                  </div>
                </div>
                <div class="row px-3 mb-1">
                  <label class="mb-1 sr-only">
                    <h6 class="mb-0 text-sm">Tanggal Shift</h6>
                  </label>
                  <input v-model="form.date" type="date" />
                </div>
                <div class="row px-3 mb-1">
                  <label class="mb-1 sr-only">
                    <h6 class="mb-0 text-sm">Shift</h6>
                  </label>
                  <select v-model="form.shift" style="background: transparent">
                    <option value="none">- Select Shift -</option>
                    <option
                      :value="item.id"
                      v-for="(item, key) in shift"
                      :key="key"
                    >
                      {{ item.time }}
                    </option>
                  </select>
                </div>

                <div
                  class="row mb-3 px-3 d-flex justify-content-between align-items-center"
                >
                  <button type="submit" class="btn btn-blue text-center">
                    Login
                  </button>
                  <small class="text-right mb-0">versi : {{ versi }}</small>
                </div>
              </form>
              <!-- <div class="row px-3 mb-4">
                <div class="line"></div>
                <small class="or text-center">Or</small>
                <div class="line"></div>
              </div>
              <div class="row mb-2 px-3">
                <div class="col-sm-12 text-center">
                  <a href="javascript:void(0)"
                    ><img
                      :src="image.google"
                      alt="Google"
                      class="img-fluid"
                      style="width: 42%"
                  /></a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex d-none d-sm-block">
      <div class="text-right">Copyright &copy; 2021. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "@/common/config/app.config.json";
import { loader } from "@/common/mixins/mix_loader";
import { lsConfig } from "@/common/mixins/mix_localStorage";
import { staffCookies } from "@/common/mixins/mix_cookies";
import { validationMixin } from "vuelidate";
import { rwSound, request, alert, util } from "@/common/mixins/mix_helper";
import { email, minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/common/API.service";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "bootstrap-notify";
import { version } from "@/common/general.config";

let hariIni = () => {
  let today = new Date();
  let [days, month, year] = [
    today.getDate(),
    today.getMonth() + 1,
    today.getFullYear(),
  ];
  month = month < 10 ? "0" + month : month;
  days = days < 10 ? "0" + days : days;
  return year + "-" + month + "-" + days;
};

Vue.use(VueReCaptcha, { siteKey: config.reCaptcha.v3.site });
export default {
  name: "Auth2",
  mixins: [
    loader,
    staffCookies,
    validationMixin,
    lsConfig,
    rwSound,
    request,
    alert,
    util,
  ],
  data() {
    return {
      image: {
        left: "/img/svg/chat.svg",
        logo: "/img/svg/rumahweb.svg",
        google: "img/rw/google_sign_in.png",
      },
      form: {
        email: "",
        password: "",
        shift: "none",
        date: hariIni(),
        reCaptcha: "",
      },
      shift: [],
      versi: version,
      showpwd: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * submit form login
     * --------------------------------------------------------------
     */
    onSubmit: function () {
      this.mixSound(0);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.form.shift !== "none") {
        this.loading();
        this.$recaptcha("login")
          .then((token_reCaptcha) => {
            this.form.reCaptcha = token_reCaptcha;
            this.sendingRequest();
          })
          .catch((e) => {
            this.$swal("Error", e.message, "error");
          });
      } else {
        this.$swal("Error", "Please select shift first.", "error");
      }
    },

    /**
     * --------------------------------------------------------------
     * proses authentication Api rest
     * --------------------------------------------------------------
     */
    sendingRequest: function () {
      this.mixConfigAppend()
        .then(() => {
          // login auth firebase as agent
          this.$store
            .dispatch("FbAuthLogin", this.form)
            .then(() => {
              this.$store
                .dispatch("staffLogin", this.form)
                .then((res) => {
                  this.$recaptchaInstance.hideBadge();
                  this.setCookies(res.data);

                  /**
                   * --------------------------------------------------------------
                   * update time vuex store untuk last time listen firebase
                   * karna proses login bukan redirect (refresh) jadi time tidak update
                   * --------------------------------------------------------------
                   */
                  this.$store.dispatch("agentLastMessage", {
                    time: Math.round(Date.now() / 1000),
                  });
                })
                .catch((error) => {
                  this.$store.dispatch("FbAuthSignOut");
                  let message = this.handleCatchAxios(
                    "FbAuthAgent",
                    error,
                    true
                  );
                  this.$swal("Error", message, "error");
                })
                .finally(() => {
                  this.loading(false);
                });
            })
            .catch((error) => {
              this.loading(false);
              this.$swal("Error", error.message + " [FB1]", "error");
            });
        })
        .catch((message) => {
          this.loading(false);
          this.$swal("Error", message, "error");
        });
    },

    /**
     * --------------------------------------------------------------
     * set cookies & localStorage after login success
     * --------------------------------------------------------------
     */
    setCookies: function (staff) {
      this.cookies.add({
        isLogin: true,
        isOnline: false, // default away
        staff: {
          id: staff.id,
          name: staff.nama,
          email: staff.email,
          deptid: staff.deptid,
          jabatan: staff.jabatan,
        },
      });

      // set token
      localStorage.setItem(config.rwStorage.agent.token, staff.token);
      this.$router.push("/agent");
    },

    /**
     * --------------------------------------------------------------
     * check field form login
     * --------------------------------------------------------------
     */
    checkField: function (field) {
      if (this.$v.form[field].$error) {
        if (this.$v.form[field].$invalid) {
          return "is-invalid";
        }
      }
      if (!this.$v.form[field].$invalid) {
        return "is-valid";
      }
    },

    /**
     * --------------------------------------------------------------
     * handle SSO login
     * --------------------------------------------------------------
     */
    ssoLogin: function () {
      this.$swal("error", "Coming soon gaess!", "warning");
    },

    /**
     * --------------------------------------------------------------
     * request data shift
     * --------------------------------------------------------------
     */
    getShift: function () {
      ApiService.get(this.URL.auth.shift)
        .then(({ data }) => {
          this.shift = data.data;
        })
        .catch(() => {
          this.mixSwalBlock("Failed get list shift. Please report dev");
        });
    },
  },
  mounted() {
    this.getShift();
  },
};
</script>

<style scoped lang="scss">
.card2 {
  margin: 0 40px;
}

.logo {
  width: 200px;
  height: 100px;
  margin-top: 20px;
  margin-left: 35px;
}

.image {
  width: 33em;
  /*height: 280px;*/
}

.border-line {
  border-right: 1px solid #eeeeee;
}

.line {
  height: 1px;
  width: 45%;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
select {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
select:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00aae5;
  outline-width: 0;
}
.is-invalid {
  border: 1px solid red;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-blue {
  background-color: #00aae5;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-blue:hover {
  background-color: #a5def9;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}

div.container-fluid {
  height: 100vh;
  background: url("/img/svg/wave.svg");
  background-size: 100vw auto;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-x: auto;

  .wrapper {
    background: url("/img/svg/304241.svg"), url("/img/svg/304241.svg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 11em;
  }
}

div.container-fluid::-webkit-scrollbar {
  width: 5px;
}

div.container-fluid::-webkit-scrollbar-thumb {
  border-left: 0;
  border-right: 0;
  background-color: #16b5ec;
}
</style>
